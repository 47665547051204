.table_container {
  min-height: 100vh;
  padding: 16px;
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.table-h1 {
  color: #757575;
}

.table-span {
  color: #000;
}

/* Footer */
.table-footer {
  width: 100%;
  background-color: #f5f5f7;
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6e6e73;
  font-size: 14px;
  padding: 3px;
  position: relative;
}

.table-footer a {
  cursor: pointer;
}

.table-footer p {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.table-footer span {
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-footer button {
  position: absolute;
  right: 6px;
  border: none;
  color: #6e6e73;
}

.table-footer button:hover {
  color: #000;
}
