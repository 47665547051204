.dash_index_cotainer {
  min-height: calc(100vh - 112px);
}

/* Home content */
.home_container {
  padding: 16px;
  padding-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home_container img {
  width: 100%;
  max-width: 500px;
}

.cards_box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 32px;
}

.cards_box .card {
  transition: 0.3s;
}

.cards_box .card:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}

.img_home_box {
  height: 275px;
  overflow: hidden;
}

.card-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
