.card_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 516px;
  margin: auto;
  position: relative;
}

.card_img_box {
  width: 100%;
}

.card_img_box img {
  width: 100%;
}

.location_box {
  padding-top: 16px;
  padding-bottom: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
  text-align: center;
}

.location_box a {
  text-decoration: none;
  background-color: #000;
  color: #fef8fb;
  padding: 6px 16px;
  border-radius: 6px;
  display: inline-block;
  border: 1px solid transparent;
  transition: 0.2s;
  cursor: pointer;
}

.location_box a:hover {
  background-color: #fef8fb;
  color: #000 !important;
  border: 1px solid #000;
}

/* Location icon */
.location-icon {
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-18px);
  }

  60% {
    transform: translateY(-9px);
  }
}

.form_card_box {
  padding: 32px;
  color: #fef8fb;
  width: 100%;
  background-color: transparent;
  position: absolute;
  bottom: 29px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 516px;
}

.form_card_box span {
  color: red;
}

.form_card_box label {
  font-size: 14px;
}

.rad {
  width: 170px;
}

.form-check-label {
  color: #fef8fb;
  font-weight: 300;
  margin-right: 16px;
}

.custom_btn {
  border: 1px solid transparent !important;
  color: #fef8fb !important;
  background-color: #000 !important;
}

.custom_btn:hover {
  background-color: #fef8fb !important;
  color: #000 !important;
  border: 1px solid #000 !important;
}

/* Audio button */
.audio_container {
  position: fixed;
  top: 22px;
  right: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

#playPauseButton {
  padding: 20px 20px;
  width: 16px;
  height: 16px;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4c3022;
  position: relative;
  z-index: 999;
}

#playPauseIcon {
  font-size: 16px;
  color: #e3e3e1;
}

/* Button animation */
.audio_container .bg,
.audio_container .button {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.audio_container .bg {
  animation: pulse 1.2s ease infinite;
  background: #4c3022;
}

.audio_container .button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 99;
  border: none;
  background: #4c3022;
  background-size: 18px;
  cursor: pointer;
  outline: none;
}

.audio_container .button a {
  position: absolute;
  color: #fff;
  font-size: 17px;
}

@keyframes pulse {
  0% {
    transform: scale(1, 1);
    opacity: 1;
  }

  100% {
    transform: scale(1.8);
    opacity: 0;
  }
}

/* Footer */
.footer {
  width: 100%;
  background-color: #f5f5f7;
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6e6e73;
  font-size: 14px;
  padding: 3px;
  position: relative;
}

.footer a {
  cursor: pointer;
}

.footer p {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.footer span {
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer button {
  position: absolute;
  right: 6px;
  border: none;
  color: #6e6e73;
}

.footer button:hover {
  color: #000;
}
